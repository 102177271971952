<template>
  <el-form v-loading.fullscreen.lock="loading" :model="ruleForm" :rules="rules" ref="ruleForm">
    <div class="register_zone login_zone">
      <BackButton class="my-4" text="返回" url="/login"></BackButton>
      <div class="register_account">
        <div>
          <div class="text-center mb-1">
            <p class="mb-2">選擇預設大頭貼</p>
            <el-avatar :size="100" fit="cover" :src="ruleForm.avatar" />
            <br />
            <el-radio-group v-model="ruleForm.gender" @change="genderChange">
              <el-radio label="Male">男生</el-radio>
              <el-radio label="Female">女生</el-radio>
            </el-radio-group>
          </div>
          <label class="red_star">* <span>姓名</span></label>
          <el-form-item prop="name">
            <el-input v-model="ruleForm.name" autocomplete="off" placeholder="請輸入姓名" />
          </el-form-item>
          <el-form-item prop="email" label="帳號 (Email)">
            <el-input disabled="true" :value="$route.query.email" autocomplete="off" placeholder="請輸入Email" />
          </el-form-item>
        </div>
        <el-form-item prop="phone" label="手機號碼 (選填)">
          <el-input v-model="ruleForm.phone" autocomplete="off" placeholder="請輸入手機號碼" />
        </el-form-item>
      </div>
      <div class="register_password">
        <label class="red_star">* <span>設定密碼 (含英數共8-20碼)</span></label>
        <el-form-item prop="password">
          <el-input v-model="ruleForm.password" type="password" autocomplete="new-password" show-password placeholder="請設定密碼" />
        </el-form-item>
      </div>
      <div class="register_passwordConfirm">
        <label class="red_star">* <span>確認密碼</span></label>
        <el-form-item prop="passwordConfirm">
          <el-input v-model="ruleForm.passwordConfirm" @keydown.enter.prevent="register" show-password autocomplete="off" placeholder="請再一次輸入密碼" />
        </el-form-item>
      </div>
      <el-checkbox v-model="agree"
        >我以詳閱並同意
        <el-button type="primary" link @click="openPDF('https://chargemestorage.blob.core.windows.net/shared-file/service_policy.pdf')">會員服務條款</el-button>
        與
        <el-button class="ms-0" type="primary" link @click="openPDF('https://chargemestorage.blob.core.windows.net/shared-file/privacy_policy.pdf')">隱私權聲明</el-button></el-checkbox
      >
      <ConfirmButton class="register_btn" @click="register()" text="註冊"></ConfirmButton>
    </div>
  </el-form>
</template>

<script>
import ConfirmButton from "../../Common/CommonElement/ConfirmButton.vue";
import BackButton from "../../Common/CommonElement/BackButton.vue";

export default {
  name: "RegisterComponent",
  components: {
    ConfirmButton,
    BackButton,
  },
  data() {
    const validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("此為必填資訊"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("密碼不可為空"));
      } else {
        if (this.ruleForm.passwordConfirm !== "") {
          this.$refs.ruleForm.validateField("passwordConfirm");
        }
        callback();
      }
    };
    const validatePasswordConfirm = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("請再次輸入密碼"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("密碼輸入錯誤"));
      } else {
        callback();
      }
    };
    return {
      agree: false,
      MembershipTermsandConditions: false,
      loading: false,
      ruleForm: {
        name: "",
        email: "",
        phone: "",
        avatar: "https://chargemestorage.blob.core.windows.net/shared-image/male.png",
        password: "",
        passwordConfirm: "",
        gender: "Male",
      },
      rules: {
        name: [{ validator: validateName, trigger: "change" }],
        password: [
          { validator: validatePassword, trigger: "change" },
          {
            pattern: /^(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%]).{8,}$/,
            message: "密碼條件：英文大寫、英文小寫、數字、符號 ! @ # $ %",
            trigger: "change",
          },
        ],
        passwordConfirm: [{ validator: validatePasswordConfirm, trigger: "change" }],
      },
    };
  },
  methods: {
    openPDF(pdfUrl) {
      window.open(pdfUrl);
    },
    genderChange(val) {
      if (val == "Male") this.ruleForm.avatar = "https://chargemestorage.blob.core.windows.net/shared-image/male.png";
      else this.ruleForm.avatar = "https://chargemestorage.blob.core.windows.net/shared-image/female.png";
    },
    async register() {
      const vm = this;

      if (!vm.agree) {
        vm.notify("error", `尚未同意 隱私權聲明、會員權責條款`);
        return;
      }
      vm.$refs["ruleForm"].validate((valid) => {
        console.log(valid);
        if (valid) {
          vm.loading = true;
          const user = {
            avatar: vm.ruleForm.avatar,
            email: vm.$route.query.email,
            name: vm.ruleForm.name,
            phone: vm.ruleForm.phone,
            password: vm.ruleForm.password,
            gender: vm.ruleForm.gender,
          };
          vm.$store
            .dispatch("auth/register", user)
            .then(() => {
              vm.notify("success", "註冊成功！");
              vm.$router.push("/MemberCenter");
            })
            .catch((err) => {
              console.log(err.response.data);
              vm.notify("error", `註冊失敗，${err.response.data.Msg}!`);
            })
            .finally(() => (vm.loading = false));
        }
        else {
          vm.notify("warning", `請確認輸入資料是否正確!`);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.register_zone {
  height: 100%;
  width: 100%;
  text-align: start;
  .register_btn {
    color: white;
    width: 100%;
  }
  .el-form-item {
    display: block;
    text-align: start;
  }
}
:deep .el-form-item {
  margin-bottom: 1rem;
}
</style>
